import "./App.css";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./pages/aboutUs";
import { Box } from "@mui/material";
import axios from "axios";
import TermsAndCondition from "./pages/terms-condition-privacy-policy/TermsAndCondition";
import PrivacyPolicy from "./pages/terms-condition-privacy-policy/PrivacyPolicy";
import { BASE_URL } from "./utils/api.utils";

axios.defaults.baseURL = BASE_URL;
function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Box padding={"0px"} mt={10}>
        <Routes>
          <Route index element={<AboutUs />} />
          {/*<Route path="/" element={<AboutUs />} />
          
          <Route index element={<TermsAndCondition />} />
          <Route index element={<PrivacyPolicy />} />*/}
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="*" element={<div style={{marginTop:"10rem"}}>OOPS!! Page not found.</div>} />
        </Routes>
      </Box>
    </div>
  );
}

export default App;
