import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { API } from "../../utils/api.utils";
import Head from "../../components/head/head";

const TermsAndCondition = () => {
  const [termAndConditionData, setTermAndConditionData] = useState(null);
  const getTermsAndconditionData = async () => {
    try {
      const res = await axios.get(API.GET_TERM_CONDITIONS);
      console.log(res?.data?.termsAndConditions?.termsCondition || "");
      setTermAndConditionData(
        res?.data?.termsAndConditions?.termsCondition || "",
      );
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getTermsAndconditionData();
  }, []);

  return (
    <>
      <Head
        title={"Terms & Conditions"}
        description={"This is Terms & Conditions page"}
      />
      <Box maxWidth={"800px"} margin={"auto"} pb={4} pt={2}>
        {/* <iframe
          width={"100%"}
          height={"100%"}
          src="https://www.termsfeed.com/live/7b3d96e8-9c14-497c-b419-b9122d0ff73a"
        ></iframe> */}
        <Box border={"2px solid black"} mb={4} width={"100%"}>
          {termAndConditionData ? parse(termAndConditionData) : ""}
        </Box>
      </Box>
    </>
  );
};

export default TermsAndCondition;
